import { extendTheme, type ThemeConfig } from '@chakra-ui/react'

const config: ThemeConfig = {
    initialColorMode: "system",
    useSystemColorMode: false,
};

const theme = extendTheme({ config });

export default theme;


export const lightTheme_colors = {
    bgColor: "#cff8dc",
    mainBgColor: "#38A169",
    btnBgColor: "#7cc9a0",

    mainFontColor: "#02012c",
    frontNameColor: "#5151d6",
    negativeColor: "#ff1a1a",

    listActivateColor: "#46ca35",
    listDeactivateColor: "#bfeea0bd",
    listDeactivateColor2: "#337c03bd",

    btnSelectColor: "#e06565",
    deactivationColor: "#5c1a05",
    inputBgColor: "#e8f6ff",
    bgHoverColor: "#dcdfe0",
    receSelectColor: "#d1c407",
    borderColor: "#eefafc",
    impossibleColor: "#ff6262",
    titleColor: "#e28787",
    errorColor: "#ff1a1a",

    boxShadowColor: "transparent",


    scrollBarColor: "transparent",
    scrollBarThumbColor: "#7796aa",
    scrollBarThumbHoverColor: "#4e5f69",
}

export const darkTheme_colors = {
    bgColor: "#3c4d53",
    bgHoverColor: "#4a5052",
    mainBgColor: "#5b737c",
    mainFontColor: "#ced4d1",
    btnBgColor: "#5d7681",
    inputBgColor: "#1f272b",
    receSelectColor: "#928905",
    borderColor: "#121216",
    deactivationColor: "#360303",
    impossibleColor: "#9c1f16",
    titleColor: "#461f1f",
    negativeColor: "#ee8f8f",
    errorColor: "#ff1a1a",
    frontNameColor: "#bab5f7",
    listActivateColor: "#83a9b9",
    listDeactivateColor: "#2f3e44",
    listDeactivateColor2: "#101618",



    boxShadowColor: "transparent",


    scrollBarColor: "transparent",
    scrollBarThumbColor: "#3c3c4b",
    scrollBarThumbHoverColor: "#21212c",

}