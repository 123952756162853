import { Circle, Flex, Grid, GridItem, Text, useColorModeValue } from "@chakra-ui/react";
import { darkTheme_colors, lightTheme_colors } from "../../../theme";
import { getHorseGradientStr, getHorseTextColor } from "../../../lib/utils";
import { motion } from "framer-motion";

export default function InfoTopList({ TopListdata }: { TopListdata: any }) {
    const NUMBER_COUNT = 6;

    // 테마에 따른 색상 설정
    const bgColor = useColorModeValue(lightTheme_colors.bgColor, darkTheme_colors.bgColor);
    const mainBgColor = useColorModeValue(lightTheme_colors.mainBgColor, darkTheme_colors.mainBgColor);
    const highlightColor = useColorModeValue(lightTheme_colors.receSelectColor, darkTheme_colors.receSelectColor);
    const mainFontColor = useColorModeValue(lightTheme_colors.mainFontColor, darkTheme_colors.mainFontColor);

    const scrollBarColor = useColorModeValue(lightTheme_colors['scrollBarColor'], darkTheme_colors['scrollBarColor']);
    const scrollBarThumbColor = useColorModeValue(lightTheme_colors['scrollBarThumbColor'], darkTheme_colors['scrollBarThumbColor']);
    const scrollBarThumbHoverColor = useColorModeValue(lightTheme_colors['scrollBarThumbHoverColor'], darkTheme_colors['scrollBarThumbHoverColor']);

    if (TopListdata === undefined) {
        return (
            <Flex h={"100%"} justifyContent={"center"} alignItems={"center"} bg={bgColor}>
                <Text color={mainFontColor}>로딩중입니다.</Text>
            </Flex>
        )
    }

    // 데이터 전처리
    TopListdata.forEach((data: any) => {
        data.b_ranking_list = data.b_ranking_str.replace(/\[|\]/g, '').split(',').map(Number);
    });

    return (
        <Flex
            h={"100%"}
            flexDirection={"column"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            bg={mainBgColor}
            overflowY="auto"
            overflowX="hidden"
            css={{
                '&::-webkit-scrollbar': {
                    width: '8px',
                    backgroundColor: scrollBarColor,
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: scrollBarThumbColor,
                    borderRadius: '4px',
                },
                '&:hover::-webkit-scrollbar-thumb': {
                    backgroundColor: scrollBarThumbHoverColor,
                },
            }}
        >
            {
                TopListdata.map((data: any, index: number) => (
                    <motion.div
                        key={data.created_at} // 고유 key 값으로 created_at 사용
                        layout // layout 속성 추가
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5, ease: "easeInOut" }} // 부드러운 애니메이션
                        style={{ width: '100%' }}
                    >
                        <Grid
                            w={'98%'}
                            h={index === 0 ? '2.5rem' : '2rem'} // 픽셀 단위로 height 설정
                            bg={index === 0 ? highlightColor : bgColor}
                            templateRows='1'
                            templateColumns='repeat(10, 1fr)'
                            margin={'0.2rem'}
                            marginY={index === 0 ? '1rem' : '0.2rem'}
                            borderRadius={'0.4rem'}
                            color={mainFontColor}
                        >
                            <GridItem
                                rowSpan={1}
                                colSpan={8}
                                overflow={'hidden'}
                            >
                                <Flex
                                    w={'100%'}
                                    h={'100%'}
                                    justifyContent={"space-around"}
                                    alignItems={"center"}
                                    margin={"0.1rem"}
                                    borderRadius={"0.3rem"}
                                >
                                    {data.b_ranking_list.map((horseNumber: any, index: number) => (
                                        index < NUMBER_COUNT && (
                                            <Circle key={index} size={"2rem"} color={getHorseTextColor(horseNumber)}
                                                bgGradient={getHorseGradientStr(horseNumber)}
                                                border={"1px solid black"}
                                            >
                                                {horseNumber}
                                            </Circle>
                                        )
                                    ))}
                                </Flex>
                            </GridItem>
                            <GridItem
                                rowSpan={1}
                                colSpan={2}
                                overflow={'hidden'}
                            >
                                <Flex h={'100%'} w={'100%'} justifyContent={"center"} alignItems={'center'} >
                                    <Flex w={'80%'} h={'80%'} justifyContent={'center'} alignItems={'center'} bg={mainBgColor} borderRadius={'0.4rem'}>
                                        {data.created_at.split("T")[1].split(".")[0]}
                                    </Flex>
                                </Flex>
                            </GridItem>
                        </Grid>
                    </motion.div>
                ))
            }
        </Flex>
    )
}
