import { atom } from "recoil";
import { IRacelist } from "../api/racelist";

// DoingRace
export const DoingRaceAtom = atom<IRacelist>({
    key: "DoingRaceAtom",
    default: {
        pk: 0,
        nationality: "",
        region: "",
        number: 0,
        hourses: 0,
        departure_datetime: "",
        result: "",
        situation: "",
        is_paied: false,
    }
})
